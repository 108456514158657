import React, { useEffect, useState } from "react";
import AuthService from "../../../../services/auth";
import { useGet } from "../../../../reducers/useGet";
import ReactLoading from "react-loading";

import SelectDataButton from "../../date-selector";
import ErrorMessage from "../../messages-panel/error-message";

import moment from "moment";

import waterFlowIcon from "../../../../assets/icons/water-flow-icon.png";

import { diffFunction } from "../../../../lib/diff-array";

export default function SumWaterFlowDataAnimalPanel({ gateway, sensorTypeID }) {
	let { access_token } = AuthService.getCurrentUser();
	// data inicial e final para o intervalo de dados do valor acumulado
	const [periodo1Acc, setPeriodo1Acc] = useState("");
	const [periodo2Acc, setPeriodo2Acc] = useState("");
	const [waterFlowAccValue, setWaterFlowAccValue] = useState(NaN); // Para o painel de valor acumulado

	let api_request_acc;
	const tipo_poco = 
	{id: 15, nome: 'Fluxo da Água Acc'}

	
	if (periodo1Acc === "") {
		let lastDay = new Date();
		lastDay.setDate(lastDay.getDate() - 1);
		lastDay = moment(lastDay).format("YYYY-MM-DDT03:00");
		api_request_acc = `evento_consulta?tipo_mensagem=1&gateway=${gateway.id_dispositivo}&tipo_sensor=${tipo_poco.id}&data:>=${lastDay}`;
		setPeriodo1Acc(new Date(moment(lastDay).format("YYYY-MM-DDT00:00")));
	} else {
		let data1 = moment(periodo1Acc).utc().format("YYYY-MM-DDTHH:mm");

		if (periodo2Acc !== "") {
			let data2 = moment(periodo2Acc).utc().format("YYYY-MM-DDTHH:mm");
			api_request_acc = `evento_consulta?tipo_mensagem=1&gateway=${gateway.id_dispositivo}&tipo_sensor=${tipo_poco.id}&data:>=${data1}&data:<=${data2}`;
		} else {
			api_request_acc = `evento_consulta?tipo_mensagem=1&gateway=${gateway.id_dispositivo}&tipo_sensor=${tipo_poco.id}&data:>=${data1}`;
		}
	}

	let getDataAcc = useGet(api_request_acc, access_token);

	useEffect(() => {
		let dataAccArray = [];

		if (
			!getDataAcc.state.loading &&
			!getDataAcc.state.error &&
			getDataAcc.state.data.length > 0
		) {
			let accValue = 0;

			getDataAcc.state.data.forEach((s) => {
				if (s.dados.length > 0) {
					let array = s.dados.map((d) => {
						return {
							timestamp: +d.timestamp,
							value: +d.valor_sensor,
						};
					});

					dataAccArray = diffFunction(array, 1024);

					let acumulado =
						dataAccArray[dataAccArray.length - 1].value -
						dataAccArray[0].value;

					accValue += acumulado;
				}
			});

			setWaterFlowAccValue(accValue);
		}
	}, [
		getDataAcc.state.loading,
		getDataAcc.state.error,
		getDataAcc.state.data,
	]);

	return (
		<div className="little-panel-container">
			<div className="panel-title-container">
				<em className="panel-title">Dados Somados dos Poços</em>
			</div>
			{getDataAcc.state.loading ? (
				<div className="panel-loading">
					<ReactLoading
						type="spinningBubbles"
						color="var(--main-color)"
						width="50px"
						height="50px"
					/>
				</div>
			) : getDataAcc.state.error ? (
				<ErrorMessage />
			) : (
				<div style={{ margin: "10px" }}>
					<SelectDataButton
						periodo1={periodo1Acc}
						setPeriodo1={setPeriodo1Acc}
						periodo2={periodo2Acc}
						setPeriodo2={setPeriodo2Acc}
						hourlyData={true}
					/>
					<div className="last-data-container">
						<div className="last-data-content-container">
							<img
								src={waterFlowIcon}
								height={"40px"}
								width={"40px"}
								alt="water-flux-icon"
								style={{ marginRight: "5px" }}
							/>

							{!isNaN(waterFlowAccValue) ? (
								<p className="last-value-text">
									{waterFlowAccValue.toFixed(2)} m³
								</p>
							) : (
								<p className="last-value-text">-- m³</p>
							)}
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
