import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import AuthService from "../../../../services/auth";
import { BarPercentageChart } from "../../charts";
import { BASE_URL } from "../../../../services/api";

export default function TankLevelDistributionPanel({ devices }) {
  const { access_token } = AuthService.getCurrentUser();
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // Estado para armazenar o erro

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log(devices[0].sensorsList.tag);
        const requests = devices.map((device) => {
          const api_request = `${BASE_URL}/evento_consulta?tipo_mensagem=1&sensor=${device.sensorsList.id_dispositivo}&ultimo_dado=1`;
          console.log(api_request);
          return fetch(api_request, {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }).then((response) => response.json());
        });

        const responses = await Promise.all(requests);
        console.log(responses);

        const formattedData = responses.map((item, index) => {
          console.log(
            "Item response:",
            devices[index].sensorsList.modelo.superior
          ); // Adiciona um console.log aqui para verificar o conteúdo de item
          return {
            farm: devices[index].sensorsList.tag, // Substitua por como você quer identificar o dispositivo
            percentage:
              item.length === 0
                ? 100 *
                    (item.valor_sensor /
                      devices[index].sensorsList.modelo.superior) || 0
                : 100 *
                    (item[0].valor_sensor /
                      devices[index].sensorsList.modelo.superior) || 0, // Substitua pelos campos corretos
          };
        });

        setAllData(formattedData);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
        setError(error); // Define o erro no estado
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [devices, access_token]);

  if (loading) {
    return (
      <div className="little-panel-container">
        <div className="panel-title-container">
          <em className="panel-title">
            Distribuição Percentual dos Níveis dos Reservatórios
          </em>
        </div>
        <div>
          <div className="loading-container">
            <ReactLoading
              type="bubbles"
              color="var(--main-color)"
              width="50px"
              height="50px"
            />
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="little-panel-container">
        <div className="panel-title-container">
          <em className="panel-title">
            Distribuição Percentual dos Níveis dos Reservatórios
          </em>
        </div>
        <div>
          <div className="error-container">
            <p style={{ color: "red", textAlign: "center" }}>
              Ocorreu um erro ao carregar os dados.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="little-panel-container">
      <div className="panel-title-container">
        <em className="panel-title">
          Distribuição Percentual dos Níveis dos Reservatórios
        </em>
      </div>
      <div>
        <div className="chart-container">
          <BarPercentageChart
            data={allData}
            unit="%"
            color="Green"
            fileName="chart"
          />
        </div>
      </div>
    </div>
  );
}
